import React from "react";

const Navbar = () => {
  return (
    <div className="text-bg-primary py-4">
      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
        <div className="ms-4">
          <h5>OpenAI Playground</h5>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
