import React, { useState } from "react";
import { Configuration, OpenAIApi } from "openai";
import config from "./config";
import Typewriter from "react-ts-typewriter";
import Navbar from "./components/Navbar";

const configuration = new Configuration({
  apiKey: config.api_key,
});

const App = () => {
  const [text, setText] = useState<string>("Tell me about OpenAI company");
  const [responseText, setResponseText] = useState("");
  const [buttonText, setButtonText] = useState("Submit");
  const openai = new OpenAIApi(configuration);

  const handleSubmit = async (e: any) => {
    // prevent form submission
    e.preventDefault();

    // Change button text to "Processing..."
    setButtonText("Processing...");

    // send request to Open AI Server
    const response = await openai.createCompletion({
      model: "text-davinci-003",
      prompt: text,
      temperature: 0,
      max_tokens: 1000,
    });

    // Change button text to "Submit"
    setButtonText("Submit");

    console.log("response", response);

    setResponseText(response.data.choices[0].text?.trim() || "");
  };

  const handleChange = (e: any) => {
    setText(e.target.value);
  };

  return (
    <div>
      <Navbar />
      <div className="container-fluid p-4">
        <div className="d-flex align-items-center my-4">
          <form className="d-flex w-100" onSubmit={handleSubmit}>
            <input
              type="text"
              className="form-control mx-2"
              value={text}
              onChange={handleChange}
            ></input>
            <button className="btn btn-primary btn-small float-end" type="submit">
              {buttonText}
            </button>
          </form>
        </div>

        <div className="my-4 py-4">
          <Typewriter text={responseText} />
        </div>
      </div>
    </div>
  );
};

export default App;
